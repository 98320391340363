import React from 'react';

import PartnersLogo from "../Common/PartnersLogo";
import PartnersLogoSkeleton from "../Common/PartnersLogoSkeleton";

class Partners extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            isLoaded: false,
        };
    }

    componentDidMount() {
        fetch('https://inric-api.iotaci.com/wp-json/wp/v2/partner')
            .then(res => res.json())
            .then(result => {
                this.setState({
                    isLoaded: true,
                    items: result
                });
            });
    }

    render() {
        const {items} = this.state;
        return (

            <section className="wow animate__fadeIn margin-6-rem-bottom p-0"
                     style={{'visibility': 'visible', 'animationName': 'fadeIn'}}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div
                            className="col-12 col-md-6 text-center margin-3-rem-bottom margin-3-rem-top md-margin-4-rem-bottom wow animate__fadeIn">
                            <h5 className="alt-font font-weight-700 text-slate-blue letter-spacing-minus-1px">
                                Our Partners
                            </h5>
                        </div>
                    </div>
                    <div className="row row-cols-1 row-cols-md-4 row-cols-sm-2 client-logo-style-02">
                        {
                            (this.state.isLoaded) ?
                                <PartnersLogo elements={items}/> :
                                <PartnersLogoSkeleton/>
                        }
                    </div>
                </div>
            </section>
        )
    }
}

export default Partners;