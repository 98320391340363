import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import IndexPage from "../pages/Home/IndexPage";
import About from "../pages/Common/About";
import Contact from "../pages/Common/Contact";
import Layout from "./layout/Layout";
// import BlogList from "../pages/Blog/BlogList";
import NotFound from "../pages/Common/NotFound";
import BlogDetail from "../pages/Blog/BlogDetail";
import ChangeRoute from "./Tools/ChangeRoute";
import BlogListByCategory from "../pages/Blog/BlogListByCategory";
import BlogListByTag from "../pages/Blog/BlogListByTag";
// import JoinUs from '../pages/Common/JoinUs'
import ServicesList from "../pages/Services/servicesList";
import FaLayout from "../fa/components/layout/Layout";
import FaIndexPage from "../fa/pages/Home/IndexPage";
import FaAbout from "../fa/pages/Common/About";
import FaContact from "../fa/pages/Common/Contact";
import FaNotFound from "../fa/pages/Common/NotFound";
import FaBlogDetail from "../fa/pages/Blog/BlogDetail";
import FaBlogListByCategory from "../fa/pages/Blog/BlogListByCategory";
import FaBlogListByTag from "../fa/pages/Blog/BlogListByTag";
import FaServicesList from "../fa/pages/Services/servicesList";

const Routing = () => {
    return (
        <Router>
            <div className="App">
                <ChangeRoute/>
                <Switch>
                    {/*en*/}
                    <Route path="/" exact>
                        <Layout>
                            <IndexPage/>
                        </Layout>
                    </Route>
                    <Route path="/services" exact>
                        <Layout>
                            <ServicesList/>
                        </Layout>
                    </Route>
                    <Route path="/about" exact>
                        <Layout>
                            <About/>
                        </Layout>
                    </Route>
                    <Route path="/contact" exact>
                        <Layout>
                            <Contact/>
                        </Layout>
                    </Route>
                    {/*<Route path="/join-us" exact>*/}
                    {/*    <JoinUs/>*/}
                    {/*</Route>*/}
                    {/*<Route path="/blog" exact>*/}
                    {/*    <Layout>*/}
                    {/*        <BlogList/>*/}
                    {/*    </Layout>*/}
                    {/*</Route>*/}
                    <Route path="/posts/detail/:slug" exact>
                        <Layout>
                            <BlogDetail/>
                        </Layout>
                    </Route>
                    <Route path="/posts/:slug" exact>
                        <Layout>
                            <BlogListByCategory/>
                        </Layout>
                    </Route>
                    <Route path="/blog/tag/:slug" exact>
                        <Layout>
                            <BlogListByTag/>
                        </Layout>
                    </Route>

                    {/*fa*/}
                    <FaLayout>
                        <Route path="/fa" exact>
                            <FaIndexPage/>
                        </Route>
                        <Route path="/fa/services" exact>
                            <FaServicesList/>
                        </Route>
                        <Route path="/fa/about" exact>
                            <FaAbout/>
                        </Route>
                        <Route path="/fa/contact" exact>
                            <FaContact/>
                        </Route>
                        <Route path="/fa/posts/detail/:slug" exact>
                            <FaBlogDetail/>
                        </Route>
                        <Route path="/fa/posts/:slug" exact>
                            <FaBlogListByCategory/>
                        </Route>
                        <Route path="/fa/post/tag/:slug" exact>
                            <FaBlogListByTag/>
                        </Route>

                    </FaLayout>
                    {/*404 EN*/}
                    <FaLayout>
                        <Route path="/fa/*" exact>
                            <NotFound/>
                        </Route>
                    </FaLayout>
                    <Layout>
                        <Route path="*" exact>
                            <NotFound/>
                        </Route>
                    </Layout>
                </Switch>
            </div>

        </Router>
    );
};

export default Routing;