import React from 'react'
import NewsLetter from "../Tools/NewsLetter";
import {Link} from "react-router-dom";

export default class Footer extends React.Component {
    render() {
        return (
            <footer className="footer-dark bg-slate-blue rtl">
                <div className="footer-top padding-six-tb lg-padding-eight-tb md-padding-50px-tb">
                    <div className="container">
                        <div className="row">

                            <div
                                className="col-12 col-lg-4 col-sm-6 order-sm-1 order-lg-0 last-paragraph-no-margin md-margin-40px-bottom xs-margin-25px-bottom">
                                <span
                                    className="alt-font font-weight-500 d-block text-white margin-20px-bottom xs-margin-10px-bottom">درباره ما</span>
                                <p>
                                    تحول صنایع از دریچه تحول دیجیتال و نوآوری صنعتی امکان پذیر است. در مرکز نوآوری انقلاب صنعتی خدماتی همچون برگزاری تورهای صنعتی، ارائه راهکارهای خلاقانه، خدمات مشاوره و ارائه برنامه های آموزش ارائه می گردد، و محل اجتماع صنایع مختلف در حوزه صنعت نفت و گاز می باشد.
                                </p>
                            </div>
                            <div
                                className="col-12 col-lg-3 offset-sm-1 col-sm-5 order-sm-2 order-lg-0 md-margin-40px-bottom xs-margin-25px-bottom">
                                <span
                                    className="alt-font font-weight-500 d-block text-white margin-20px-bottom xs-margin-10px-bottom">دسترسی سریع</span>
                                <ul>
                                    <li><Link to="/fa/services">خدمات</Link></li>
                                    <li><Link to="/fa/contact">تماس با ما</Link></li>
                                    <li><Link to="/fa/about">درباره ما</Link></li>
                                </ul>
                            </div>
                            <div className="col-12 col-xl-3 offset-xl-1 col-lg-4 col-sm-6 order-sm-3 order-lg-0">
                                <span
                                    className="alt-font font-weight-500 d-block text-white margin-20px-bottom xs-margin-10px-bottom">با ما در تماس باشید</span>
                                <p>
                                    برای دریافت آخرین اخبار مربوط به مرکز نوآوری انقلاب صنعتی ایمیل خود را وارد کنید.
                                </p>
                                <NewsLetter/>
                            </div>

                        </div>
                    </div>
                </div>
                <div
                    className="footer-bottom padding-35px-tb border-top border-width-1px border-color-white-transparent">
                    <div className="container">
                        <div className="row align-items-center">
                            <div
                                className="col-12 col-md-12 text-center last-paragraph-no-margin sm-margin-20px-bottom">
                                <p>© {new Date().getFullYear()} تمامی حقوق برای مرکز نوآوری انقلاب صنعتی محفوظ می باشد</p>
                            </div>

                        </div>
                    </div>
                </div>
            </footer>

        )
    }
}