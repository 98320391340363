import React, {useContext, useEffect, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Autoplay, Keyboard, Pagination} from "swiper";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {SliderDataContext} from "../../../context/SliderDataContext";

const Slider = () => {
    const {sliderItems, setSliderItems} = useContext(SliderDataContext);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (sliderItems.length > 0) {
            setIsLoaded(true)
        }
    }, [sliderItems])

    return (
        <section className="p-0 main-slider margin-3-rem-bottom">
            <Swiper
                modules={[Navigation, Pagination, Autoplay, Keyboard]}
                className={'swiper-container white-move mobileoff-fullscreen-top-space md-h-600px sm-h-500px'}
                spaceBetween={0}
                slidesPerView={1}
                loop={true}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false
                }}
                keyboard={{enabled: true, onlyInViewport: true}}
                navigation={{
                    prevEl: ".swiper-button-previous-nav",
                    nextEl: ".swiper-button-next-nav",
                }}
                pagination={{
                    el: ".swiper-pagination",
                    clickable: true,
                }}
            >
                <div className="swiper-wrapper">
                    {
                        (isLoaded) ?

                            sliderItems.map((item, i) =>
                                <SwiperSlide className="cover-background"
                                             style={{"backgroundImage": "url('" + item.image.guid + "')"}} key={i}>
                                    <div className="overlay-bg bg-gradient-dark-slate-blue"/>
                                    <div className="container h-100">
                                        <div className="row h-100 justify-content-end">
                                            <div
                                                className="col-12 col-xl-8 col-lg-7 col-sm-8 h-75 d-flex justify-content-center flex-column position-relative rtl">

                                                {
                                                    (item.content.rendered.length>0) ?
                                                        <div
                                                            className="alt-font text-extra-large text-white opacity-7 font-weight-300"
                                                            dangerouslySetInnerHTML={{__html: item.content.rendered}}/>
                                                        : ''
                                                }

                                                <h4 className="alt-font text-shadow-double-large font-weight-600 text-white margin-55px-bottom w-90 md-w-100 md-no-text-shadow xs-margin-35px-bottom text-end">
                                                    {item.fa_name}
                                                </h4>
                                                <div className="d-inline-block">
                                                    <a href={item.fa_button_link} rel="noreferrer"
                                                       className="btn btn-fancy btn-medium btn-primary margin-30px-right xs-margin-15px-bottom">{item.fa_button_text}</a>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ) :
                            <SwiperSlide className="cover-background">
                                <Skeleton height={"100%"}/>
                            </SwiperSlide>

                    }

                </div>

                <div className="swiper-pagination swiper-light-pagination"/>

            </Swiper>
        </section>
    );
};

export default Slider;
