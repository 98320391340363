import React from 'react';
import {Link} from "react-router-dom";
import PartnersLogo from "../../components/Common/PartnersLogo";
import {Helmet} from "react-helmet";
import PartnersLogoSkeleton from "../../components/Common/PartnersLogoSkeleton";

class About extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            isLoaded: false,
        };
    }

    componentDidMount() {
        // fetch('https://inric-api.iotaci.com/wp-json/wp/v2/partner')
        //     .then(res => res.json())
        //     .then(result => {
        //         this.setState({
        //             isLoaded: true,
        //             items: result
        //         });
        //     });
    }

    render() {
        const {items} = this.state;
        return (
            <>
                <Helmet>
                    <title> INRIC - About Us</title>
                </Helmet>

                <section
                    className="wow animate__fadeIn bg-light-gray padding-40px-tb sm-padding-30px-tb page-title-small"
                    style={{visibility: "visible", animationName: "fadeIn"}}>
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-xl-8 col-lg-6 text-center text-lg-start">
                                <h1 className="alt-font text-extra-dark-gray font-weight-500 no-margin-bottom d-inline-block">
                                    About Us
                                </h1>
                                {/*<span className="alt-font text-medium d-block d-md-inline-block sm-margin-5px-top">Short tagline goes here</span>*/}
                            </div>
                            <div
                                className="col-xl-4 col-lg-6 text-center text-lg-end breadcrumb justify-content-center justify-content-lg-end text-small alt-font md-margin-15px-top">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li className="text-primary">About Us</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </section>
                <section className="big-section pb-2 pb-md-5">
                    <div className="container">
                        <div className="row justify-content-center">
                            {/*<div className="col-md-12 text-center margin-eight-bottom">*/}
                            <div className="col-md-12 text-center">
                                <h4 className="alt-font text-extra-dark-gray letter-spacing-minus-1px font-weight-600 wow animate__fadeIn">
                                    We are INRIC</h4>
                                {/*<p className="w-100 xs-w-100">INRIC is a multidisciplinary company in the field of Internet of Things (IoT) based products and services, mostly active in Oil and Gas industries.</p>*/}
                            </div>
                        </div>
                        {/*<div className="row row-cols-1 row-cols-md-4 row-cols-sm-2 client-logo-style-02">*/}
                        {/*    {*/}
                        {/*        (this.state.isLoaded === true) ?*/}
                        {/*            <PartnersLogo elements={items}/> :*/}
                        {/*            <PartnersLogoSkeleton/>*/}
                        {/*    }*/}
                        {/*</div>*/}
                    </div>
                </section>
                {/*About Us-----------------------*/}
                <section className="big-section cover-background pt-0"
                         style={{
                             backgroundImage: "url('/assets/images/bg-aboutabout.jpg')"
                         }}>
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div
                                className="col-10 col-lg-6 position-relative md-margin-9-rem-bottom wow animate__fadeIn">
                                <div className="w-70 overflow-hidden position-relative xs-w-80">
                                    <img src="/assets/images/aboutus-1-1.jpg" alt=""/>
                                </div>
                                <div
                                    className="position-absolute right-35px bottom-minus-50px w-50 lg-bottom-minus-30px xs-right-15px"
                                    data-wow-delay="0.2s" data-parallax-layout-ratio="1.1">
                                    <img src="/assets/images/aboutus2.jpg" alt=""/>
                                </div>
                            </div>
                            <div className="col-10 col-xl-4 col-lg-5 offset-lg-1">
                                <h4 className="alt-font text-extra-dark-gray letter-spacing-minus-1px font-weight-600 margin-4-rem-bottom md-margin-3-rem-bottom wow animate__fadeIn">
                                    We provide you with high precision safety in the industry.</h4>
                                <div className="row">

                                    <div className="col-12 wow animate__fadeIn" data-wow-delay="0.2s">
                                        <div className="feature-box feature-box-left-icon">
                                            <div className="feature-box-content last-paragraph-no-margin">
                                            <span
                                                className="alt-font text-black font-weight-500 d-block margin-5px-bottom">INRIC Vision</span>
                                                <p className="w-85 xs-w-100">
                                                    Our goal is to revolutionize industries through digital transformation and industrial innovation. We empower businesses to excel in the digital age by harnessing cutting-edge technologies and achieving unparalleled success.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 wow animate__fadeIn" data-wow-delay="0.2s">
                                        <div
                                            className="w-100 h-1px bg-medium-gray margin-3-rem-tb md-margin-2-rem-tb"/>
                                    </div>

                                    <div className="col-12 wow animate__fadeIn" data-wow-delay="0.4s">
                                        <div className="feature-box feature-box-left-icon">
                                            <div className="feature-box-content last-paragraph-no-margin">
                                            <span
                                                className="alt-font text-black font-weight-500 d-block margin-5px-bottom">INRIC Mission</span>
                                                <p className="w-85 xs-w-100">
                                                    Our mission is to provide businesses with holistic digital transformation and industrial innovation solutions that drive sustainable growth and competitive advantage. Through our services, we equip individuals and organizations with the skills and knowledge to navigate the evolving digital landscape.
                                                </p>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*Company -----------------------*/}
                <section className="big-section overlap-height wow animate__fadeIn section-aboutus">
                    <div className="container">
                        <div className="row align-items-center overlap-gap-section">
                            <div
                                className="col-12 col-lg-5 col-md-8 order-md-2 order-lg-1 sm-margin-5-rem-bottom wow animate__fadeIn"
                                data-wow-delay="0.1s">
                                <h4 className="alt-font font-weight-600 text-slate-blue letter-spacing-minus-1px margin-30px-bottom sm-margin-20px-bottom">About INRIC</h4>
                                <p className="w-80 lg-w-100">
                                    At Industrial Revolution Innovation Center, we specialize in helping businesses revolutionize their industries through the power of digital transformation and industrial innovation. Our services, including Industrial Tours, Solution Provider, B2B Matchmaking, and Training Services, provide individuals and organizations with the knowledge and skills needed to leverage cutting-edge technologies such as IoT, IIoT, Industry 4.0, and Industry 5.0. Our team of experts is dedicated to staying at the forefront of the latest trends and best practices in these fields. Join us on a journey of digital transformation and industrial innovation to discover new opportunities for success in the digital age.                                </p>

                            </div>
                            <div
                                className="col-12 col-lg-4 order-md-1 order-lg-2 md-margin-5-rem-bottom wow animate__fadeIn"
                                data-wow-delay="0.3s">
                                <img src="/assets/images/aboutus.jpg" alt="" className="border-radius-6px"/>
                            </div>
                            <div className="col-12 col-lg-2 offset-md-1 col-md-3 order-md-3 wow animate__fadeIn"
                                 data-wow-delay="0.5s">
                                <div className="row align-items-center justify-content-center">

                                    <div className="col-12 text-center text-sm-start">
                                        <div
                                            className="d-flex flex-row align-item-start margin-15px-bottom xs-margin-10px-bottom justify-content-center justify-content-sm-start">
                                            <h4 className="vertical-counter d-inline-flex text-extra-dark-gray alt-font appear font-weight-600 letter-spacing-minus-2px mb-0 sm-letter-spacing-minus-1-half"
                                                >4000 </h4><span
                                            className="text-extra-dark-gray title-extra-small font-weight-500 margin-5px-left">+</span>
                                        </div>
                                        <span className="alt-font text-medium text-uppercase d-block">people <br/>trained</span>
                                        <div
                                            className="w-100 h-1px bg-medium-gray margin-2-rem-tb xs-margin-3-rem-tb"></div>
                                    </div>

                                    <div className="col-12 text-center text-sm-start">
                                        <div
                                            className="d-flex flex-row align-item-start margin-15px-bottom xs-margin-10px-bottom justify-content-center justify-content-sm-start">
                                            <h4 className="vertical-counter d-inline-flex text-extra-dark-gray alt-font appear font-weight-600 letter-spacing-minus-2px mb-0 sm-letter-spacing-minus-1-half"
                                                >30</h4><span
                                            className="text-extra-dark-gray title-extra-small font-weight-500 margin-5px-left">+</span>
                                        </div>
                                        <span className="alt-font text-medium text-uppercase d-block">companies <br/>consulted</span>
                                        <div
                                            className="w-100 h-1px bg-medium-gray margin-2-rem-tb xs-margin-3-rem-tb"></div>
                                    </div>

                                    <div className="col-12 text-center text-sm-start">
                                        <div
                                            className="d-flex flex-row align-item-start margin-15px-bottom xs-margin-10px-bottom justify-content-center justify-content-sm-start">
                                            <h4 className="vertical-counter d-inline-flex text-extra-dark-gray alt-font appear font-weight-600 letter-spacing-minus-2px mb-0 sm-letter-spacing-minus-1-half"
                                                >300</h4><span
                                            className="text-extra-dark-gray title-extra-small font-weight-500 margin-5px-left">+</span>
                                        </div>
                                        <span className="alt-font text-medium text-uppercase d-block">training  <br/>courses held</span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    };
}

export default About;
