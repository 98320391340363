import React from 'react';
import {Link} from "react-router-dom";


class BlogTags extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [], isLoaded: false,
        };
    }

    componentDidMount() {

        fetch('https://inric-api.iotaci.com/wp-json/wp/v2/tags')
            .then(res => res.json())
            .then(result => {
                this.setState({
                    isLoaded: true, items: result
                });
            });
    }

    render() {
        const {items} = this.state;
        return (

            <>
                <div
                    className="margin-5-rem-bottom xs-margin-35px-bottom md-padding-3-rem-top wow animate__fadeIn">
                        <span
                            className="alt-font font-weight-500 text-large text-extra-dark-gray d-block margin-35px-bottom">Tags
                            cloud</span>
                    <div className="tag-cloud">
                        {
                            items.map((item, i) =>
                                <Link  key={i} to={"/blog/tag/" + item.slug}>{item.name}</Link>
                            )
                        }

                    </div>
                </div>





            </>
        );
    }

}

export default BlogTags;
