import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {ServiceDataProvider} from "./context/ServiceDataContext";
import {SliderDataProvider} from "./context/SliderDataContext";
import "@fontsource/poppins"

ReactDOM.render(
    <ServiceDataProvider>
        <SliderDataProvider>
            <App/>
        </SliderDataProvider>
    </ServiceDataProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorkerRegistration.register();