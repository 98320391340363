import React from "react";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Keyboard} from "swiper";
import BlogCard from "../Blog/BlogCard";
import BlogCardSkeleton from "../Blog/BlogCardSkeleton";

class Blog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            isLoaded: false,
        };
    }

    componentDidMount() {
        fetch('https://inric-api.iotaci.com/?rest_route=/wp/v2/posts&_embed&per_page=6&page=1&categories=1,13')
            .then(res => res.json())
            .then(result => {
                this.setState({
                    isLoaded: true,
                    items: result
                });
            });

    }

    render() {
        const {items} = this.state;
        return (
            <section className="margin-3-rem-bottom p-0">
                <div className="container">
                    <div className="row justify-content-center">
                        <div
                            className="col-12 col-md-6 text-center margin-3-rem-bottom margin-3-rem-top md-margin-4-rem-bottom wow animate__fadeIn">
                            <h5 className="alt-font font-weight-700 text-slate-blue letter-spacing-minus-1px">
                                Latest
                                News
                            </h5>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div
                            className="col-12 col-md-9 col-lg-12 position-relative wow animate__fadeIn"
                            data-wow-delay="0.2s">
                            <Swiper
                                modules={[Navigation, Keyboard]}
                                className={'black-move blog-classic'}
                                spaceBetween={30}
                                slidesPerView={1}
                                loop={true}
                                breakpoints={{
                                    992: {
                                        slidesPerView: 3
                                    }
                                }}
                                keyboard={{enabled: true, onlyInViewport: true}}
                                navigation={{
                                    prevEl: ".swiper-button-previous-nav",
                                    nextEl: ".swiper-button-next-nav",
                                }}
                            >

                                <div className="swiper-wrapper">
                                    {
                                        (this.state.isLoaded === true) ?
                                            items.map((item, i) =>
                                                <SwiperSlide key={i}>
                                                    <BlogCard elements={item}/>
                                                </SwiperSlide>
                                            ) :
                                            Array.apply(0, Array(3)).map((item, i) =>
                                                <div key={i} className={"col-lg-4 col-md-6 col-12"}>
                                                    <BlogCardSkeleton/>
                                                </div>
                                            )

                                    }
                                </div>

                            </Swiper>

                            <div
                                className="swiper-button-previous-nav swiper-button-prev light slider-navigation-style-07 rounded-circle box-shadow-double-large">
                                <i className="feather icon-feather-arrow-left"/></div>
                            <div
                                className="swiper-button-next-nav swiper-button-next light slider-navigation-style-07 rounded-circle box-shadow-double-large">
                                <i className="feather icon-feather-arrow-right"/></div>

                        </div>
                    </div>

                </div>
            </section>
        )
    }
}

export default Blog;