import {Link} from "react-router-dom";
import React from "react";

const About = () => {

    return (
        <section className="big-section pt-0">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div
                        className="col-10 col-lg-6 position-relative md-margin-9-rem-bottom wow animate__fadeIn">
                        <div className="w-70 overflow-hidden position-relative xs-w-100">
                            <img src="/assets/images/aboutus-1-1.jpg" alt=""/>
                        </div>
                        <div
                            className="position-absolute right-35px bottom-minus-50px w-50 lg-bottom-minus-30px xs-right-15px"
                            data-wow-delay="0.2s" data-parallax-layout-ratio="1.1">
                            <img src="/assets/images/aboutus2.jpg" alt=""/>
                        </div>
                    </div>
                    <div className="col-10 col-xl-4 col-lg-5 offset-lg-1">
  <span
      className="alt-font font-weight-600 text-neon-orange text-uppercase letter-spacing-2px d-block margin-30px-bottom xs-margin-20px-bottom"/>
                        <h4 className="alt-font font-weight-700 text-slate-blue letter-spacing-minus-1px text-uppercase margin-35px-bottom">
                            WE OFFER HIGH-QUALITY SERVICES IN THE IOT INDUSTRY
                        </h4>
                        <p>
                            INRIC  is  a  multidisciplinary  company  specializing  in  IoT-based  products  and  services,  primarily focusing on the Oil and Gas industries
                        </p>
                        <Link to="/contact" className="btn btn-large btn-primary margin-20px-top">JOIN US NOW</Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;