import Routing from "./components/Routing";
import {useContext, useEffect} from "react";
import {ServiceDataContext} from "./context/ServiceDataContext";
import {SliderDataContext} from "./context/SliderDataContext";

function App() {
    const {serviceItems, setServiceItems} = useContext(ServiceDataContext);
    const {sliderItems, setSliderItems} = useContext(SliderDataContext);

    const getServicesData = async () => {
        const baseURL = 'https://inric-api.iotaci.com/wp-json/wp/v2/services?_fields[]=title&_fields[]=content&_fields[]=img&_fields[]=slug&_fields[]=fa_name&_fields[]=fa_description';
        try {
            const response = await fetch(baseURL);
            let data = await response.json()
            setServiceItems(data);
        } catch (err) {
            console.error(err);
        }
    };

    const getSliderData = async () => {
        const baseURL = 'https://inric-api.iotaci.com/wp-json/wp/v2/slider?_fields[]=image&_fields[]=title&_fields[]=button_link&_fields[]=button_text&_fields[]=content&_fields[]=fa_name&_fields[]=fa_button_link&_fields[]=fa_button_text';
        try {
            const response = await fetch(baseURL);
            let data = await response.json()
            setSliderItems(data);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        getServicesData();
        getSliderData();
    }, [])

    return (
        <Routing/>
    );
}

export default App;
