import React from 'react';
import {Link} from "react-router-dom";
import PartnersLogo from "../../components/Common/PartnersLogo";
import {Helmet} from "react-helmet";
import PartnersLogoSkeleton from "../../components/Common/PartnersLogoSkeleton";

class About extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            isLoaded: false,
        };
    }

    componentDidMount() {
        // fetch('https://inric-api.iotaci.com/wp-json/wp/v2/partner')
        //     .then(res => res.json())
        //     .then(result => {
        //         this.setState({
        //             isLoaded: true,
        //             items: result
        //         });
        //     });
    }

    render() {
        const {items} = this.state;
        return (
            <>
                <Helmet>
                    <title> INRIC - درباره ما</title>
                </Helmet>

                <section
                    className="wow animate__fadeIn bg-light-gray padding-40px-tb sm-padding-30px-tb page-title-small"
                    style={{visibility: "visible", animationName: "fadeIn"}}>
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-xl-8 col-lg-6 text-center text-lg-start">
                                <h1 className="alt-font text-extra-dark-gray font-weight-500 no-margin-bottom d-inline-block">
                                    درباره ما
                                </h1>
                                {/*<span className="alt-font text-medium d-block d-md-inline-block sm-margin-5px-top">Short tagline goes here</span>*/}
                            </div>
                            <div
                                className="col-xl-4 col-lg-6 text-center text-lg-end breadcrumb justify-content-center justify-content-lg-end text-small alt-font md-margin-15px-top">
                                <ul>
                                    <li><Link to="/fa">خانه</Link></li>
                                    <li className="text-primary">درباره ما</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </section>
                <section className="big-section pb-2 pb-md-5">
                    <div className="container">
                        <div className="row justify-content-center">
                            {/*<div className="col-md-12 text-center margin-eight-bottom">*/}
                            <div className="col-md-12 text-center">
                                <h4 className="alt-font text-extra-dark-gray letter-spacing-minus-1px font-weight-600 wow animate__fadeIn">
                                    مرکز نوآوری انقلاب صنعتی </h4>
                                {/*<p className="w-100 xs-w-100">INRIC is a multidisciplinary company in the field of Internet of Things (IoT) based products and services, mostly active in Oil and Gas industries.</p>*/}
                            </div>
                        </div>
                        {/*<div className="row row-cols-1 row-cols-md-4 row-cols-sm-2 client-logo-style-02">*/}
                        {/*    {*/}
                        {/*        (this.state.isLoaded === true) ?*/}
                        {/*            <PartnersLogo elements={items}/> :*/}
                        {/*            <PartnersLogoSkeleton/>*/}
                        {/*    }*/}
                        {/*</div>*/}
                    </div>
                </section>
                {/*About Us-----------------------*/}
                <section className="big-section cover-background pt-0"
                         style={{
                             backgroundImage: "url('/assets/images/bg-aboutabout.jpg')"
                         }}>
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div
                                className="col-10 col-lg-6 position-relative md-margin-9-rem-bottom wow animate__fadeIn">
                                <div className="w-70 overflow-hidden position-relative xs-w-80">
                                    <img src="/assets/images/aboutus-1-1.jpg" alt=""/>
                                </div>
                                <div
                                    className="position-absolute right-35px bottom-minus-50px w-50 lg-bottom-minus-30px xs-right-15px"
                                    data-wow-delay="0.2s" data-parallax-layout-ratio="1.1">
                                    <img src="/assets/images/aboutus2.jpg" alt=""/>
                                </div>
                            </div>
                            <div className="col-10 col-xl-4 col-lg-5 offset-lg-1">
                                <h4 className="alt-font text-extra-large text-extra-dark-gray letter-spacing-minus-1px font-weight-600 margin-2-rem-bottom margin-2-rem-top md-margin-3-rem-bottom wow animate__fadeIn rtl">
                                    ارائه بهترین خدمات در حوزه انقلاب صنعتی چهارم و پنجم
                                </h4>
                                <div className="row">

                                    <div className="col-12 wow animate__fadeIn" data-wow-delay="0.2s">
                                        <div className="feature-box feature-box-left-icon rtl text-end">
                                            <div className="feature-box-content last-paragraph-no-margin">
                                            <span
                                                className="alt-font text-black font-weight-500 d-block margin-5px-bottom">چشم انداز</span>
                                                <p className="w-85 xs-w-100">
                                                    هدف این مرکز توسعه صنایع از منظر تحول دیجیتال و نوآوری صنعتی است. با بهره‌گیری از فناوری‌های پیشرفته و دستیابی به علوم نوین، کسب و کارها را در عصر دیجیتال توانمند می کنیم.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 wow animate__fadeIn" data-wow-delay="0.2s">
                                        <div
                                            className="w-100 h-1px bg-medium-gray margin-3-rem-tb md-margin-2-rem-tb"/>
                                    </div>

                                    <div className="col-12 wow animate__fadeIn" data-wow-delay="0.4s">
                                        <div className="feature-box feature-box-left-icon rtl">
                                            <div className="feature-box-content last-paragraph-no-margin text-end">
                                            <span
                                                className="alt-font text-black font-weight-500 d-block margin-5px-bottom">مأموریت</span>
                                                <p className="w-85 xs-w-100">
                                                    ماموریت ما ارائه راه حل های تحول دیجیتال و نوآوری صنعتی به کسب و کارها است که باعث رشد پایدار و مزیت رقابتی می شود. ما از طریق خدمات خود، افراد و سازمان‌ها را با مهارت‌ها و دانش‌هایی مجهز می‌کنیم تا در چشم‌انداز دیجیتال در حال تحول حرکت کنند.
                                                </p>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*Company -----------------------*/}
                <section className="big-section overlap-height wow animate__fadeIn section-aboutus">
                    <div className="container">
                        <div className="row align-items-center overlap-gap-section">
                            <div
                                className="col-12 col-lg-5 col-md-8 order-md-2 order-lg-1 sm-margin-5-rem-bottom wow animate__fadeIn rtl"
                                data-wow-delay="0.1s">
                                <h4 className="alt-font font-weight-600 text-slate-blue letter-spacing-minus-1px margin-30px-bottom sm-margin-20px-bottom">
                                    درباره ما
                                </h4>
                                <p className="w-80 lg-w-100">
                                    در مرکز نوآوری انقلاب صنعتی، از طریق تحول دیجیتال و نوآوری صنعتی، کسب و کارها را در مسیر توسعه و هوشمند شدن قرار می دهیم. خدمات این مرکز از جمله تورهای صنعتی، ارائه راهکارهای نوآورانه، جلسات کسب و کاری، و خدمات آموزشی، آنچه را که افراد و سازمان ها نیاز دارند تا در مسیر توسعه و تحول قرار بگیرند را فراهم می نماید. متخصصین این مرکز در خط مقدم فناوری های نوین در کنار شما هستند تا با بهره مندی از آخرین و به روزترین راهکارها، صنایع و سازمان خود را در مسیر پیشرفت و نوآوری قرار دهید.
                                    </p>

                            </div>
                            <div
                                className="col-12 col-lg-4 order-md-1 order-lg-2 md-margin-5-rem-bottom wow animate__fadeIn"
                                data-wow-delay="0.3s">
                                <img src="/assets/images/aboutus.jpg" alt="" className="border-radius-6px"/>
                            </div>
                            <div className="col-12 col-lg-2 offset-md-1 col-md-3 order-md-3 wow animate__fadeIn"
                                 data-wow-delay="0.5s">
                                <div className="row align-items-center justify-content-center">

                                    <div className="col-12 text-center text-sm-start">
                                        <div
                                            className="d-flex flex-row align-item-start margin-15px-bottom xs-margin-10px-bottom justify-content-center justify-content-sm-start">
                                            <h4 className="vertical-counter d-inline-flex text-extra-dark-gray alt-font appear font-weight-600 letter-spacing-minus-2px mb-0 sm-letter-spacing-minus-1-half"
                                                >4000 </h4><span
                                            className="text-extra-dark-gray title-extra-small font-weight-500 margin-5px-left">+</span>
                                        </div>
                                        <span className="alt-font text-large text-uppercase d-block">نفرات <br/> آموزش دیده</span>
                                        <div
                                            className="w-100 h-1px bg-medium-gray margin-2-rem-tb xs-margin-3-rem-tb"></div>
                                    </div>

                                    <div className="col-12 text-center text-sm-start">
                                        <div
                                            className="d-flex flex-row align-item-start margin-15px-bottom xs-margin-10px-bottom justify-content-center justify-content-sm-start">
                                            <h4 className="vertical-counter d-inline-flex text-extra-dark-gray alt-font appear font-weight-600 letter-spacing-minus-2px mb-0 sm-letter-spacing-minus-1-half"
                                                >30</h4><span
                                            className="text-extra-dark-gray title-extra-small font-weight-500 margin-5px-left">+</span>
                                        </div>
                                        <span className="alt-font text-large text-uppercase d-block">خدمات <br/>مشاوره</span>
                                        <div
                                            className="w-100 h-1px bg-medium-gray margin-2-rem-tb xs-margin-3-rem-tb"></div>
                                    </div>

                                    <div className="col-12 text-center text-sm-start">
                                        <div
                                            className="d-flex flex-row align-item-start margin-15px-bottom xs-margin-10px-bottom justify-content-center justify-content-sm-start">
                                            <h4 className="vertical-counter d-inline-flex text-extra-dark-gray alt-font appear font-weight-800 letter-spacing-minus-2px mb-0 sm-letter-spacing-minus-1-half"
                                                >300</h4><span
                                            className="text-extra-dark-gray title-extra-small font-weight-500 margin-5px-left">+</span>
                                        </div>
                                        <span className="alt-font text-large text-uppercase d-block">دروه آموزشی  <br/>برگزار شده</span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    };
}

export default About;
