import React from 'react';
import {Link} from "react-router-dom";

const Banner = () => {
    return (

        // <section className="cover-background wow animate__fadeIn margin-3-rem-bottom"
        <section className="cover-background wow animate__fadeIn"
                 style={{
                     background: "rgba(45, 45, 45, 0.71) url('/assets/images/position.jpg')",
                     backgroundAttachment: "fixed",
                     backgroundBlendMode: "multiply"
                 }}>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-xl-8 col-lg-8 mx-auto text-center wow animate__fadeIn">

                        <h3 className="alt-font text-white font-weight-500 margin-40px-bottom">
                            برای خلق ایده های نو همین حالا به مرکز نوآوری انقلاب صنعتی بپیوندید
                        </h3>
                        <Link to="/fa/contact"
                              className="btn btn-fancy btn-large btn-primary margin-30px-right xs-margin-15px-bottom">
                            با ما در ارتباط باشید
                            <span
                                className="bg-transparent-black"/></Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Banner;