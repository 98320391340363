import React from 'react';
import {Link} from "react-router-dom";

const BlogCard = (props) => {
    let item = props.elements
    return (
        <div className="grid-item wow animate__fadeIn" data-wow-delay="0.2s">
            <div className="blog-post margin-20px-bottom">
                <div
                    className="blog-post-image margin-15px-bottom md-margin-35px-bottom xs-margin-25px-bottom">
                    <Link to={"/fa/posts/detail/" + item.slug}>
                        <img
                            src={(item.featured_media != 0) ? item.better_featured_image.source_url : null}
                            alt={item.title.rendered}/>
                    </Link>
                </div>
                <div
                    className="post-details margin-15px-bottom md-margin-10px-bottom xs-no-margin-bottom">
                    <Link to={"/fa/posts/detail/" + item.slug}
                       className="alt-font font-weight-500 text-extra-medium text-extra-dark-gray d-block margin-10px-bottom xs-margin-10px-bottom blogtextheight rtl">
                        <span dangerouslySetInnerHTML={{__html :(item.title.rendered.length > 60) ? item.title.rendered.substring(0, 57) + '...': item.title.rendered}} />
                    </Link>
                    <p className="w-95 mb-2 rtl">{new Date(item.date).toLocaleDateString('fa-IR', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                    })}</p>
                    <span
                        className="separator bg-primary"/>
                    <Link href={"/fa/posts/detail/" + item.slug}
                       className="alt-font font-weight-500 text-extra-small text-uppercase text-primary">
                        ادامه مطلب
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default BlogCard;