import React from 'react';
import {Link} from "react-router-dom";

const ServiceCard = (props) => {
    let item = props.elements
    return (

        <div className="blog-post bg-white box-shadow-medium margin-30px-bottom wow animate__fadeIn"
             style={{visibility: "visible", animationName: "fadeIn"}}>
            <div className="d-flex flex-column flex-md-row align-items-center">
                <div className="blog-post-image sm-margin-25px-bottom">
                    <Link to="#" title="">
                        <img src={item.img.guid} alt="" data-no-retina=""/>
                    </Link>
                </div>
                <div
                    className="post-details padding-4-half-rem-lr md-padding-2-half-rem-lr sm-no-padding">

                    <Link to="/services"
                          className="alt-font font-weight-500 text-extra-large text-extra-dark-gray d-block margin-20px-bottom sm-margin-10px-bottom">{item.fa_name}</Link>
                    <div className="margin-seventeen-bottom sm-margin-25px-bottom"
                         dangerouslySetInnerHTML={{__html: item.fa_description}}/>
                    <div
                        className="alt-font text-extra-small text-uppercase d-flex align-items-center sm-margin-10px-bottom">
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceCard;