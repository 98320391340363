import React, {useState} from 'react';

const NewsLetter = () => {
    const [abs, setAbs] = useState(0)
    const NewsLetterSubmit = (e) => {
        e.preventDefault();
        let mailId = document.getElementsByName('emailId')[0].value;
        if (mailId !== "") {
            let formdata = new FormData();
            formdata.append("news-email", mailId);

            let requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch("https://inric-api.iotaci.com/wp-json/contact-form-7/v1/contact-forms/242/feedback", requestOptions)
                .then(response => response.text())
                .then(result => {
                    setAbs(1)
                })
                .catch(error => console.log('error', error));
        }
    }
    if (abs === 0)
        return (

            <form>
                <div className="newsletter-style-03 position-relative">
                    <input className="small-input border-color-medium-gray rounded m-0 required"
                           name="emailId" placeholder="ایمیل خود را وارد کنید" type="email"/>
                    <input type="hidden" name="redirect" value=""/>
                    <button
                        className="btn no-border btn-primary rounded-end submit"
                        type="button" onClick={NewsLetterSubmit}><i
                        className="feather icon-feather-mail icon-extra-small m-0"></i>
                    </button>

                </div>
            </form>
        );
    else
        return (
            <div className="form-results rounded">
                You Successfully Registered.
            </div>
        )

};

export default NewsLetter;