import React, {useContext, useEffect, useState} from "react";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Autoplay, Keyboard} from "swiper";
import {Link} from "react-router-dom";
import {ServiceDataContext} from "../../context/ServiceDataContext";

const Services = () => {
    const {serviceItems, setServiceItems} = useContext(ServiceDataContext);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (serviceItems.length > 0) {
            setIsLoaded(true)
        }
    }, [serviceItems])

    return (
        <section className="wow animate__fadeIn p-0 margin-3-rem-bottom">
            <div className="row justify-content-center">
                <div
                    className="col-12 col-md-6 text-center margin-3-rem-bottom margin-3-rem-top md-margin-4-rem-bottom wow animate__fadeIn">
                    <h5 className="alt-font font-weight-700 text-slate-blue letter-spacing-minus-1px">Our
                        Services</h5>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <Swiper
                        modules={[Navigation, Autoplay, Keyboard]}
                        className={'swiper-container slider-zoom-slide slider-zoom-slide-dark black-move p-0'}
                        spaceBetween={60}
                        slidesPerView={1}
                        loop={true}
                        initialSlide={2}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false
                        }}
                        breakpoints={{
                            992: {
                                slidesPerView: 2,
                                centeredSlides: true
                            },
                            767: {
                                slidesPerView: 1,
                                centeredSlides: false
                            }
                        }}
                        keyboard={{enabled: true, onlyInViewport: true}}
                        navigation={{
                            prevEl: ".swiper-button-previous-nav",
                            nextEl: ".swiper-button-next-nav",
                        }}
                    >

                        <div className="swiper-wrapper">
                            {
                                serviceItems.map((item, i) =>
                                    <SwiperSlide key={i}>
                                        <div
                                            className="col-12 h-100 cover-background align-items-start d-flex justify-content-end padding-4-half-rem-all lg-padding-3-rem-all md-padding-4-rem-all xs-padding-3-rem-all"
                                            style={{"backgroundImage": "url('" + item.img.guid + "')"}}>
                                            <div
                                                className="slider-zoom-slide-dark-overlay opacity-very-light bg-extra-dark-gray"/>
                                            <div
                                                className="d-flex flex-column w-50 bg-white box-shadow-extra-large no-transition padding-4-half-rem-all pt-0 slider-zoom-content z-index-1 position-relative xl-w-75 xl-padding-3-rem-all lg-w-80 lg-padding-2-half-rem-all md-w-55 md-padding-4-half-rem-all sm-w-60 xs-w-100"
                                                style={{height: '350px'}}
                                            >
                                                <h4 className="slider-zoom-label alt-font font-weight-500 bg-fast-yellow text-white padding-15px-bottom padding-6-rem-top align-self-end margin-2-half-rem-bottom sm-padding-9-rem-top">{(i + 1 < 10) ? '0' : ''}{i + 1}</h4>
                                                <h5 className="alt-font text-extra-dark-gray"> {item.title.rendered}<span
                                                    // className="font-weight-600"> {item.boldtitle}</span>
                                                />
                                                </h5>

                                                {/*{{__html: (item.content.rendered.length>60)?item.content.rendered(0, 57) :item.content.rendered}}*/}
                                                <Link to="/services"
                                                      className="alt-font font-weight-500 text-extra-dark-gray text-greenish-gray-hover">
                                                    View more
                                                    <i className="feather icon-feather-arrow-right align-middle margin-15px-left"/>
                                                </Link>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )}


                        </div>


                    </Swiper>
                </div>
            </div>
        </section>
    );
};

export default Services;