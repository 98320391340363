import React from 'react';
import {Link} from "react-router-dom";
import BlogCard from "../../components/Blog/BlogCard";
import {Helmet} from "react-helmet";
import BlogCardSkeleton from "../../components/Blog/BlogCardSkeleton";

class BlogListByTag extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [], isLoaded: false, PageName: '', pageNumber: 1, itemStart: 0, itemEnd: 6, activePage: 1,
        };
    }

    componentDidMount() {
        let slug = window.location.pathname.split('/')[3]
        fetch('https://inric-api.iotaci.com/wp-json/wp/v2/tags/?slug=' + slug)
            .then(res => res.json())
            .then(result => {
                this.setState({
                    PageName: result[0].name
                });
                getCatPosts(result[0].id)
            });

        const getCatPosts = (e) => {
            // fetch('https://inric-api.iotaci.com/?rest_route=/wp/v2/posts&_embed&per_page=6&page=1')
            fetch('https://inric-api.iotaci.com/?rest_route=/wp/v2/posts&_embed&tags=' + e)
                .then(res => res.json())
                .then(result => {
                    let postCounts = result.length;
                    let pageNumberCount = Math.floor(Number(postCounts) / this.state.itemEnd) + 1;

                    this.setState({
                        isLoaded: true, items: result, pageNumber: pageNumberCount
                    });
                });
        }

    }


    render() {
        const states = this.state;
        const goToPage = e => {
            window.scrollTo(0, 0);
            let gtNumb = parseInt(e.target.attributes['data-gt'].value);
            let iStart = 0;
            let iEnd = 6;
            let newEnd = gtNumb * iEnd;
            let newStart = newEnd - iEnd;
            this.setState({
                itemStart: newStart, itemEnd: newEnd, activePage: gtNumb
            })
        }

        const prevPage = () => {
            window.scrollTo(0, 0);
            let gtNumb = (states.activePage > 1) ? states.activePage - 1 : states.activePage;
            let iStart = 0;
            let iEnd = 6;
            let newEnd = gtNumb * iEnd //4
            let newStart = newEnd - iEnd
            this.setState({
                itemStart: newStart, itemEnd: newEnd, activePage: gtNumb
            })
        }

        const nextPage = () => {
            window.scrollTo(0, 0);
            let gtNumb = (states.activePage !== states.pageNumber) ? states.activePage + 1 : states.activePage;
            let iStart = 0;
            let iEnd = 6;
            let newEnd = gtNumb * iEnd //4
            let newStart = newEnd - iEnd
            this.setState({
                itemStart: newStart, itemEnd: newEnd, activePage: gtNumb
            })
        }

        return (

            <>
                <Helmet>
                    <title>INRIC - News: {states.PageName}</title>
                </Helmet>
                <section
                    className="wow animate__fadeIn bg-light-gray padding-40px-tb sm-padding-30px-tb page-title-small"
                    style={{"visibility": "visible", "animation-name": "fadeIn"}}>
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-xl-8 col-lg-6 text-center text-lg-start">
                                <h1 className="alt-font text-extra-dark-gray font-weight-500 no-margin-bottom d-inline-block">
                                    Tag: {states.PageName}
                                </h1>
                                {/*<span className="alt-font text-medium d-block d-md-inline-block sm-margin-5px-top">Short tagline goes here</span>*/}
                            </div>
                            <div
                                className="col-xl-4 col-lg-6 text-center text-lg-end breadcrumb justify-content-center justify-content-lg-end text-small alt-font md-margin-15px-top">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/blog">News</Link></li>
                                    <li>{states.PageName}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="padding-eleven-lr xl-padding-two-lr xs-no-padding-lr">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 blog-content">
                                <div className="blog-classic grid row gutter-double-extra-large">
                                    <div className="grid-sizer"/>
                                    {
                                        (this.state.isLoaded === true) ?
                                            states.items.slice(states.itemStart, states.itemEnd).map((item, i) =>
                                                <div key={i} className={"col-lg-4 col-md-6 col-12"}>
                                                    <BlogCard elements={item}/>
                                                </div>
                                            ) :
                                            Array.apply(0, Array(6)).map((item, i) =>
                                                <div key={i} className={"col-lg-4 col-md-6 col-12"}>
                                                    <BlogCardSkeleton/>
                                                </div>
                                            )

                                    }
                                </div>
                                <div
                                    className="col-12 d-flex justify-content-center margin-7-half-rem-top lg-margin-4-rem-top sm-margin-5-rem-top wow animate__fadeIn">
                                    <ul className="pagination pagination-style-01 text-small font-weight-500 align-items-center">
                                        <li className="page-item"><p className="page-link" onClick={prevPage}><i
                                            className="feather icon-feather-arrow-left icon-extra-small d-xs-none"/>
                                        </p>
                                        </li>

                                        {(() => {
                                            let number = states.pageNumber
                                            const options = [];
                                            for (let i = 1; i <= number; i++) {
                                                options.push(
                                                    <li className={(states.activePage === i) ? 'page-item active' : 'page-item'}>
                                                        <p className="page-link" onClick={goToPage} data-gt={i}>{i}</p>
                                                    </li>);
                                            }

                                            return options;
                                        })()}


                                        <li className="page-item"><p className="page-link" onClick={nextPage}><i
                                            className="feather icon-feather-arrow-right icon-extra-small d-xs-none"></i>
                                        </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }

}

export default BlogListByTag;
