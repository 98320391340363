import React from 'react'
import NewsLetter from "../Tools/NewsLetter";
import {Link} from "react-router-dom";

export default class Footer extends React.Component {
    render() {
        return (
            <footer className="footer-dark bg-slate-blue">
                <div className="footer-top padding-six-tb lg-padding-eight-tb md-padding-50px-tb">
                    <div className="container">
                        <div className="row">

                            <div
                                className="col-12 col-lg-4 col-sm-6 order-sm-1 order-lg-0 last-paragraph-no-margin md-margin-40px-bottom xs-margin-25px-bottom">
                                <span
                                    className="alt-font font-weight-500 d-block text-white margin-20px-bottom xs-margin-10px-bottom">About incric</span>
                                <p className="alt-font">
                                    Revolutionizing industries through digital transformation and industrial innovation. Services include Industrial Tours, Solution Provider, B2B Matchmaking, and Training Services. Join us on a journey of innovation to discover new opportunities for success in the digital age.                                </p>
                            </div>
                            <div
                                className="col-12 col-lg-3 offset-sm-1 col-sm-5 order-sm-2 order-lg-0 md-margin-40px-bottom xs-margin-25px-bottom">
                                <span
                                    className="alt-font font-weight-500 d-block text-white margin-20px-bottom xs-margin-10px-bottom">Quick Access</span>
                                <ul>
                                    <li><Link to="/services">Services</Link></li>
                                    <li><Link to="/contact">Contact us</Link></li>
                                    <li><Link to="/about">About us</Link></li>
                                </ul>
                            </div>
                            <div className="col-12 col-xl-3 offset-xl-1 col-lg-4 col-sm-6 order-sm-3 order-lg-0">
                                <span
                                    className="alt-font font-weight-500 d-block text-white margin-20px-bottom xs-margin-10px-bottom">Get In Touch</span>
                                <p>Enter your email address for receiving valuable newsletters.</p>
                                <NewsLetter/>
                            </div>

                        </div>
                    </div>
                </div>
                <div
                    className="footer-bottom padding-35px-tb border-top border-width-1px border-color-white-transparent">
                    <div className="container">
                        <div className="row align-items-center">
                            <div
                                className="col-12 col-md-12 text-center last-paragraph-no-margin sm-margin-20px-bottom alt-font">
                                <p>© {new Date().getFullYear()} All rights reserved INRIC</p>
                            </div>

                        </div>
                    </div>
                </div>
            </footer>

        )
    }
}